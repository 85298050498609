import AdminCreateStoneInner from "../../component/admin-create-stone";
import AuthProvider from "../../context/auth";
import AdminLayout from "../../layout";

const AdminCreateStone = () => {
  return (
    <AuthProvider>
      <AdminLayout>
        <AdminCreateStoneInner />
      </AdminLayout>
    </AuthProvider>
  );
};

export default AdminCreateStone;
