import { Link } from "react-router-dom";
import { useNews } from "../../data/data.service";
import AdminNewsItem from "../admin-news-item";
import { useState } from "react";
import EditNewsModal from "../edit-news-modal";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { deleteNews } from "../../data/data.fn";
import { toast } from "react-toastify";

const AdminNewsInner = () => {
  const { data, isLoading, refetch } = useNews();

  const [isDelete, setIsDelete] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [currentNews, setCurrentNews] = useState(false);

  console.log(data);

  const handleClose = () => {
    setIsDelete(false);
    setCurrentNews(null);
  };

  const mutation = useMutation({
    mutationFn: deleteNews,
    onSuccess: (data) => {
      if (data.success) {
        toast.success("Yangilik o'chirildi");

        refetch();
        console.log(data);
      }
    },
    onError: () => {
      toast.error("Hatolik yuz berdi qaytadan urinib ko'ring");
      refetch();
    },
  });

  const handleDeleteNews = () => {
    mutation.mutate(currentNews?._id);
    setIsDelete(false);
    setCurrentNews(null);
  };

  return (
    <div className="dashboard">
      <EditNewsModal
        isEdit={isEdit}
        refetch={refetch}
        setEdit={setEdit}
        setCurrentNews={setCurrentNews}
        currentNews={currentNews}
      />
      <div>
        <Dialog
          open={isDelete}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Siz ${currentNews?.title_uz?.slice(
              0,
              50
            )}... ni o'chirib tashlashni hohlaysizmi?`}
          </DialogTitle>

          <DialogActions>
            <Button onClick={handleClose}>Yo'q</Button>
            <Button onClick={handleDeleteNews}>Ha</Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className="dashboard_container">
        <div className="dashboard_link-wrapper">
          <h2 className="dashboard_heading">Yangiliklar</h2>
          <Link to={"/admin/create/news"} className="dashboard_link">
            Yangilik qoshish
          </Link>
        </div>
        {isLoading ? (
          <b className="block text-center">Yuklanmoqda...</b>
        ) : !isLoading && data?.data?.News.length > 0 ? (
          <div className="dashboard_wrapper">
            {data?.data?.News?.map((item) => (
              <AdminNewsItem
                key={item?._id}
                item={item}
                setCurrentNews={setCurrentNews}
                setEdit={setEdit}
                setIsDelete={setIsDelete}
              />
            ))}
          </div>
        ) : (
          <b className="block text-center">Yangiliklar yo'q</b>
        )}
      </div>
    </div>
  );
};

export default AdminNewsInner;
