import { createRef, lazy, useEffect, useState } from "react";

// const ReactToPdf = lazy(() => import("react-to-pdf"))

import ReactToPdf from "react-to-pdf";
import Footer from "../../component/Footer/footer";
import Navbar from "../../component/Navbar/navbar";
import { useParams } from "react-router-dom";
import { useOneProduct } from "../../data/data.service";
import { useMemo } from "react";
import { baseUrl } from "../../utils/axios";
import PdfDocument from "../../component/pdf";
import ReactPDF, { PDFViewer } from "@react-pdf/renderer";
import BasicDocument from "../../component/smth";
import { useTranslation } from "react-i18next";
import FirstPdfPage from './index'
const PdfPage = () => {
  const id = useParams().id;
  console.log(id);
  const ref = createRef();

  const { data, isLoading, error } = useOneProduct(id);

  const {t} = useTranslation()

  console.log(data);

  // const product = useMemo(() => {
  //   if (data?.success) {
  //     return data?.data?.Projects[0];
  //   }
  //   return null;
  // });
  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column", paddingTop: "100px" }}
      className=""
    >
      <Navbar navClass="nav-light" text={"#000"} />

      {isLoading ? (
        <b
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%)",
            fontSize: "20px",
          }}
          className="loading"
        >
          {t("loading")}
        </b>
      ) : !isLoading && !data?.data.Projects ? (
        <b
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%)",
            fontSize: "20px",
          }}
          className="loading"
        >
          Not found
        </b>
      ) : (
        <div className=""></div>
      )}

      <div className="pdf-container">
        {/* <PDFViewer>
          <PdfDocument />
        </PDFViewer> */}
        {data?.data?.Projects && <FirstPdfPage data={data?.data?.Projects} />}
        {data?.data?.Projects && <BasicDocument data={data?.data?.Projects} />}
      </div>

      <Footer />
    </div>
  );
};

export default PdfPage;
