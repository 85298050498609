import { Link } from "react-router-dom"
import AuthProvider from "../../context/auth"

const Admin = () => {
    return (
        <AuthProvider>
            <div className="admin-page">
                <img src="/logo.png" alt="" className="admin-logo" />
                <h2 className="admin-heading">Diamonds and colored stones bourse</h2>
                <div className="admin-links">
                    <Link className="admin-link" to={"/admin/stones"}>
                        Olmoslar
                    </Link>
                    <Link className="admin-link" to={"/admin/news"}>
                        Yangiliklar
                    </Link>
                </div>
                <div className="admin-links">
                    <Link className="admin-link" to={"/admin/create/stone"}>
                        Olmoslar qoshish
                    </Link>
                    <Link className="admin-link" to={"/admin/create/news"}>
                        Yangiliklar qoshish
                    </Link>
                </div>
            </div>
        </AuthProvider>
    )
}

export default Admin