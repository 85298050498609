import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// import logo_icon_64 from "../../assets/images/logo-icon-64.png";
import shape_image from "../../assets/images/shape-image.png";
// import imageP from "../../assets/images/cowork/1.jpg";
// import image1 from "../../assets/images/cowork/2.jpg";
// import image2 from "../../assets/images/cowork/3.jpg";
// import image3 from "../../assets/images/cowork/4.jpg";
// import image4 from "../../assets/images/cowork/5.jpg";
// import image5 from "../../assets/images/cowork/6.jpg";
// import cta from "../../assets/images/digital/cta.jpg";
// import image6 from "../../assets/images/cowork/7.jpg";
// import image7 from "../../assets/images/cowork/8.jpg";
// import image8 from "../../assets/images/cowork/9.jpg";
// import image9 from "../../assets/images/cowork/10.jpg";
import image16 from "../../assets/images/blog/01.jpg";
import image17 from "../../assets/images/blog/02.jpg";
import image18 from "../../assets/images/blog/03.jpg";

import Navbar from "../../component/Navbar/navbar";
import Footer from "../../component/Footer/footer";
import UserFeedBack2 from "../../component/userFeedBack2";
// import CookieModal from "../../component/cookieModal";

// import TinySlider from "tiny-slider-react";

import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/css/modal-video.css";

import { MessageCircle, User } from "feather-icons-react/build/IconComponents";
import HelpcenterFaqs from "../helpcenter/helpcenterFaqs";
import { useNews } from "../../data/data.service";
import { baseUrl } from "../../utils/axios";
import { useTranslation } from "react-i18next";

const settings = {
  container: ".tiny-six-item",
  controls: true,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  controlsText: [
    '<i class="mdi mdi-chevron-left "></i>',
    '<i class="mdi mdi-chevron-right"></i>',
  ],
  nav: false,
  speed: 400,
  gutter: 0,
  responsive: {
    1025: {
      items: 6,
    },

    992: {
      items: 4,
    },

    767: {
      items: 3,
    },

    320: {
      items: 1,
    },
  },
};
export default function IndexCoworking() {
  const navigate = useNavigate();

  const [isOpen, setOpen] = useState(false);
  const [isOpenTab, setisOpen] = useState(1);
  const [modal, setModal] = useState(false);

  const { t, i18n } = useTranslation();

  const services = [
    {
      iamge: "/img/diamond-1.jpg",
      name: "Private Office",
      role: "Co-space",
    },
    {
      iamge: "/img/diamond-2.jpg",
      name: "Dedicated Space",
      role: "Co-space",
    },
    {
      iamge: "/img/diamond-3.jpeg",
      name: "Small Office",
      role: "Co-space",
    },
    {
      iamge: "/img/diamond-4.webp",
      name: "Floating Seat",
      role: "Co-space",
    },
    {
      iamge: "/img/diamond-5.jpg",
      name: "Startups Desk",
      role: "Co-space",
    },
    {
      iamge: "/img/diamond-6.webp",
      name: "Retail Space",
      role: "Co-space",
    },
  ];
  const data = [
    {
      id: 1,
      title: t("service-1-head"),
      description: t("service-1-desc"),
      // image: image6,
    },
    {
      id: 2,
      title: t("service-2-head"),
      description: t("service-2-desc"),
          // image: image7,
    },
    {
      id: 3,
      title: t("service-3-head"),
      description: t("service-3-desc"),
      // image: image8,
    },
    {
      id: 4,
      title: t("service-4-head"),
      description: t("service-4-desc"),
      // image: image9,
    },
    {
      id: 5,
      title: t("service-5-head"),
      description: t("service-5-desc"),
      // image: image9,
    },
  ];

  const comments = [
    {
      description:t('comment-1'),
      // image: image8,
      name: "Calvin Carlo",
      role: "Manager",
    },
    {
      description:t('comment-2'),
      // image: image9,
      name: "Christa Smith",
      role: "Manager",
    },
    {
      description:t('comment-3'),
      // image: image10,
      name: "Jemina CLone",
      role: "Manager",
    },
    {
      description:t('comment-4'),
      // image: image11,
      name: "Smith Vodka",
      role: "Manager",
    },
    {
      description:t('comment-5'),
      // image: imageP,
      name: "Cristino Murfi",
      role: "Manager",
    }
  ];

  const stonesData = [
    {
      id: 1,
      name: t("stone-1"),
      image: "/img/stones/stone-1.png",
    },
    {
      id: 2,
      name: t("stone-2"),
      image: "/img/stones/stone-2.png",
    },
    {
      id: 3,
      name: t("stone-3"),
      image: "/img/stones/stone-3.png",
    },
    {
      id: 4,
      name: t("stone-4"),
      image: "/img/stones/stone-4.png",
    },
    {
      id: 5,
      name: t("stone-5"),
      image: "/img/stones/stone-5.png",
    },
    {
      id: 6,
      name: t("stone-6"),
      image: "/img/stones/stone-6.png",
    },
  ];

  const { data: newsData, isLoading, refetch } = useNews();

  const handleTabClick = (index) => {
    setisOpen(index);
  };

  const [searchVal, setSearchVal] = useState("");
  return (
    <>
      <Navbar navClass="nav-light" />

      <section className="relative md:py-60 py-36 items-center">
        <div className="absolute top-0 start-0 w-full bg-black h-full z-0 pointer-events-none overflow-hidden">
        </div>
        <div className="absolute inset-0 opacity-20 bg-[url('../../assets/images/map.svg')] bg-no-repeat bg-center bg-cover"></div>
        <div className="absolute inset-0 bg-black/70"></div>

        <div className="container relative">
          <div className="grid grid-cols-1 -mt-10 text-center">
            <img
              src={"/logo.png"}
              width={90}
              height={90}
              className="block mx-auto animate-[spin_10s_linear_infinite]"
              alt=""
            />
            <h3 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 mt-10 text-white">
              {t("hero-heading")}
            </h3>

            <p className="text-slate-300 text-lg max-w-2xl mx-auto">
              {t("hero-subheading")}
            </p>

            <div className="subcribe-form z-1 mt-8">
              <form className="relative mx-auto max-w-xl">
                {/* <MapPin className="w-5 h-5 absolute top-[48%] -translate-y-1/2 start-4"></MapPin> */}
                <input
                  value={searchVal}
                  onChange={(e) => {
                    setSearchVal(e.target.value);
                  }}
                  type="text"
                  id="search_name"
                  name="name"
                  className="pt-4 pe-40 pb-4 w-full h-[50px] outline-none text-black dark:text-white rounded-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 ps-6"
                  placeholder={t("search-by-id")}
                />
                <button
                  onClick={() => {
                    navigate(`/pdf/${searchVal}`);
                  }}
                  type="submit"
                  className="py-2 px-5 inline-block font-semibold tracking-wide align-middle transition duration-500 ease-in-out text-base text-center absolute top-[2px] end-[3px] h-[46px] bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white rounded-full"
                >
                  {t("search")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            <div className="md:col-span-6">
              <div className="lg:me-8">
                <img src={shape_image} alt="" />
              </div>
            </div>

            <div className="md:col-span-6">
              <div className="lg:me-5">
                <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">
                  {t("about-subheading")}
                </h6>
                <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                  {t("about-heading")}
                </h3>

                <p className="text-slate-400 max-w-xl mb-6">
                  {t("about-description")}
                </p>

                <button
                  onClick={() => {
                    setModal(true);
                  }}
                  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600/5 hover:bg-indigo-600 border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white rounded-full"
                >
                  {t("contact")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          id="services"
          style={{ paddingTop: "50px" }}
          className="container relative"
        >
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              {t("our-services")}
            </h3>

            <p className="text-slate-400 max-w-3xl mx-auto">
              {t("services-description")}
            </p>
          </div>
          <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
            <div className="lg:col-span-4 md:col-span-5">
              <div className="sticky top-20">
                <ul
                  className="flex-column text-center p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md"
                  id="myTab"
                  data-tabs-toggle="#myTabContent"
                  role="tablist"
                >
                  {data?.map((item) => {
                    return (
                      <li role="presentation">
                        <button
                          className={`${
                            isOpenTab === item.id
                              ? "text-white bg-indigo-600 hover:text-white"
                              : ""
                          } px-4 py-2 text-base font-semibold rounded-md w-full hover:text-indigo-600 transition-all duration-500 ease-in-out`}
                          id="profile-tab"
                          data-tabs-target="#profile"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="true"
                          onClick={() => {
                            setisOpen(item.id);
                          }}
                        >
                          {item.title}
                        </button>
                      </li>
                    );
                  })}
                  {/* <li role="presentation">
                    <button
                      onClick={() => handleTabClick(1)}
                      className={`${
                        isOpenTab === 1
                          ? "text-white bg-indigo-600 hover:text-white"
                          : ""
                      } px-4 py-2 text-base font-semibold rounded-md w-full mt-3 hover:text-indigo-600 transition-all duration-500 ease-in-out`}
                      id="dashboard-tab"
                      data-tabs-target="#dashboard"
                      type="button"
                      role="tab"
                      aria-controls="dashboard"
                      aria-selected="false"
                    >
                      Dedicated Office
                    </button>
                  </li>
                  <li role="presentation">
                    <button
                      onClick={() => handleTabClick(2)}
                      className={`${
                        isOpenTab === 2
                          ? "text-white bg-indigo-600 hover:text-white"
                          : ""
                      } px-4 py-2 text-base font-semibold rounded-md w-full mt-3 hover:text-indigo-600 transition-all duration-500 ease-in-out`}
                      id="settings-tab"
                      data-tabs-target="#settings"
                      type="button"
                      role="tab"
                      aria-controls="settings"
                      aria-selected="false"
                    >
                      Kitchen Space
                    </button>
                  </li>
                  <li role="presentation">
                    <button
                      onClick={() => handleTabClick(3)}
                      className={`${
                        isOpenTab === 3
                          ? "text-white bg-indigo-600 hover:text-white"
                          : ""
                      } px-4 py-2 text-base font-semibold rounded-md w-full mt-3 hover:text-indigo-600 transition-all duration-500 ease-in-out`}
                      id="contacts-tab"
                      data-tabs-target="#contacts"
                      type="button"
                      role="tab"
                      aria-controls="contacts"
                      aria-selected="false"
                    >
                      Meeting Rooms
                    </button>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="lg:col-span-8 md:col-span-7">
              <div
                id="myTabContent"
                className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md"
              >
                {data.map((item, index) => {
                  return (
                    <div
                      className={`${isOpenTab === item.id ? "" : "hidden"}`}
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                      key={index}
                    >
                      {/* <img
                        src={item.image}
                        className="shadow rounded-md"
                        alt=""
                      /> */}
                      <div className="mt-6">
                        <h5 className="text-lg font-semibold mb-4">
                          {item.title}
                        </h5>
                        <p className="text-slate-400 mb-2">
                          {item.description}
                        </p>
                        {/* <Link
                          to="#"
                          className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500"
                        >
                          See More{" "}
                          <i className="uil uil-arrow-right align-middle"></i>
                        </Link> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div id="stones" className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              {t("stones-heading")}
            </h3>

            {/* <p className="text-slate-400 max-w-xl mx-auto">
              Start working with Tailwind CSS that can provide everything you
              need to generate awareness, drive traffic, connect.
            </p> */}
          </div>
        </div>

        <div className="container relative">
          <div className="stones__wrapper">
            {stonesData?.map((item) => (
              <div key={item.id} className="stone">
                <img src={item.image} alt="" className="stone_img" />
                <div className="stone_content">
                  <p className="text-black/70  text-lg block font-semibold duration-500 ease-in-out">
                    {item.name}
                  </p>
                  {/* <p className="text-black/60 block">Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus cumque porro numquam vitae commodi perferendis.</p> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="relative md:py-24 py-16 md:pt-0 pt-0">
        <div className="container relative">
          <div className="grid grid-cols-1 justify-center">
            <div className="relative z-1">
              <div className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                <div className="lg:col-start-2 lg:col-span-10">
                  <div className="relative">
                    <img
                      src={"/img/diamond-2.jpg"}
                      className="rounded-md shadow-lg video_img"
                      alt=""
                    />
                    <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                      <Link
                        to="#"
                        onClick={() => setOpen(true)}
                        data-id="S_CGed6E610"
                        className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600 dark:text-white"
                      >
                        <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content md:mt-8">
                <div className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                  <div className="lg:col-start-2 lg:col-span-10">
                    <div className="text-center">
                      <div className="mt-2">
                        <div className="section-title text-md-start">
                          {/* <h6 className="text-white/50 text-lg font-semibold">
                          Интервью с нашим основателем
                          </h6> */}
                          <h3 className="md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-white mt-2">
                            {t("interview")}
                          </h3>
                        </div>
                      </div>

                      <div className="section-title text-md-start">
                        {/* <p className="text-white/50 max-w-xl mx-auto mb-2">
                          Start working with Techwind that can provide
                          everything you need to generate awareness, drive
                          traffic, connect.
                        </p> */}
                        {/* <Link to="#" className="text-white">
                          Read More{" "}
                          <i className="uil uil-angle-right-b align-middle"></i>
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="tfs5sWqXcb8"
          onClose={() => setOpen(false)}
        />

        <div className="absolute bottom-0 start-0 end-0 sm:h-2/3 h-4/5 bg-gradient-to-b from-indigo-500 to-indigo-600"></div>
      </section>

      <HelpcenterFaqs />

      <section className="realtive md:py-24 py-16">
        <div id="comments" className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              {t("clients-head")}
            </h3>

            {/* <p className="text-slate-400 max-w-xl mx-auto">
              Search all the open positions on the web. Get your own
              personalized salary estimate. Read reviews on over 30000+
              companies worldwide.
            </p> */}
          </div>

          <UserFeedBack2 data={comments} />
        </div>

        <div id="blog" className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-12 grid-cols-1 mb-8 items-center">
            <div className="md:col-span-6">
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                {t("blog-head-1")} {t("blog-head-2")}
              </h3>
            </div>

            {/* <div className="md:col-span-6">
              <p className="text-slate-400 max-w-xl">
                Start working with Techwind that can provide everything you need
                to generate awareness, drive traffic, connect.
              </p>
            </div> */}
          </div>

          {isLoading ? (
            <b className="block text-center text-xl">{t("loading")}</b>
          ) : !isLoading && newsData ? (
            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px]">
              {newsData?.data?.News?.map((item) => (
                <div
                  key={item?._id}
                  className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden"
                >
                  <img
                    className="w-full h-[230px] object-cover"
                    src={baseUrl + "uploads/" + item?.image}
                    alt=""
                  />

                  <div className="content p-6">
                    <p
                      // to="/blog-detail"
                      className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out"
                    >
                      {i18n.language?.includes("ru") ?   item?.title_ru : i18n.language?.includes("en") ? item?.title_en : item?.title_uz}
                    </p>
                    <p className="text-slate-400 mt-3 line-clamp-2">
                      {i18n.language?.includes("ru") ?   item?.text_ru : i18n.language?.includes("en") ? item?.text_en : item?.text_uz}
                    </p>

                    {/* <div className="mt-4">
                  <Link
                    to="/blog-detail"
                    className="relative inline-block font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500"
                  >
                    Read More <i className="uil uil-arrow-right"></i>
                  </Link>
                </div> */}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </section>

      <div className="container-fluid relative">
        <div className="grid grid-cols-1">
          <div className="relative grayscale w-full leading-[0] border-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2997.1109085289613!2d69.17848517543803!3d41.30645080101271!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8967c2a8fd13%3A0x2d697bc7b169b643!2z0YPQuy4g0KjRg9GK0LvQsCwg0KLQsNGI0LrQtdC90YIsINCj0LfQsdC10LrQuNGB0YLQsNC9!5e0!3m2!1sru!2s!4v1693200340468!5m2!1sru!2s"
              title="Google Maps Embed"
              style={{ border: "0" }}
              className="w-full h-[500px]"
              allowFullScreen
            ></iframe>
          </div>
          <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center">
            <Link
              onClick={() => setModal(!modal)}
              className="h-12 w-12 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full"
            >
              <i className="uil uil-phone"></i>
            </Link>
          </div>
        </div>
      </div>

      {modal ? (
        <div
          id="contactModal"
          className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40 flex items-center justify-center"
        >
          <div className="relative w-full h-auto max-w-md p-4">
            <div className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
              <div className="flex justify-between items-center px-6 py-4 border-b border-gray-100 dark:border-gray-700">
                <h5 className="text-xl font-semibold">{t("contact-form")}</h5>
                <button
                  type="button"
                  onClick={() => setModal(!modal)}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              <div className="p-6 text-center">
                <form>
                  <div className="grid lg:grid-cols-12 lg:gap-6">
                    <div className="col-span-12 mb-5">
                      <div className="text-start">
                        <label htmlFor="name" className="font-semibold">
                          {t("name")}:
                        </label>
                        <div className="form-icon relative mt-2">
                          <User className="w-4 h-4 absolute top-3 start-4"></User>
                          <input
                            name="name"
                            id="name"
                            type="text"
                            className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder={t("name")}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="lg:col-span-6 mb-5">
                      <div className="text-start">
                        <label htmlFor="email" className="font-semibold">
                          Your Email:
                        </label>
                        <div className="form-icon relative mt-2">
                          <Mail className="w-4 h-4 absolute top-3 start-4"></Mail>
                          <input
                            name="email"
                            id="email"
                            type="email"
                            className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="Email :"
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="grid grid-cols-1">
                    {/* <div className="mb-5">
                      <div className="text-start">
                        <label htmlFor="subject" className="font-semibold">
                          Your Question:
                        </label>
                        <div className="form-icon relative mt-2">
                          <Book className="w-4 h-4 absolute top-3 start-4"></Book>
                          <input
                            name="subject"
                            id="subject"
                            className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="Subject :"
                          />
                        </div>
                      </div>
                    </div> */}

                    <div className="mb-5">
                      <div className="text-start">
                        <label htmlFor="comments" className="font-semibold">
                          {t("message")}:
                        </label>
                        <div className="form-icon relative mt-2">
                          <MessageCircle className="w-4 h-4 absolute top-3 start-4"></MessageCircle>
                          <textarea
                            name="comments"
                            id="comments"
                            className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder={t("message")}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    id="submit"
                    name="send"
                    className="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md justify-center flex items-center"
                  >
                    {t("send")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <Footer />

      {/* <CookieModal /> */}
    </>
  );
}
