import { TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import { createProduct } from "../../data/data.fn";
import { useNavigate } from "react-router-dom";

const AdminCreateStoneInner = () => {
  const [stone, setStone] = useState("");
//   const [report_no, setReport_no] = useState("")
  const [diamond_wt, setDiamond_wt] = useState("");
  const [measurement, setMeasurement] = useState("");
  const [color, setColor] = useState("");
  const [clarity, setClarity] = useState("");
  const [cut, setCut] = useState("");
  const [cut_and_shape, setCut_and_shape] = useState("");
  const [result, setResult] = useState("");
  const [weight, setWeight] = useState("");
  const [fineness, setFineness] = useState("");
  const [data, setData] = useState("");

  const [file, setFile] = useState(null);
  const [imgSrc, setImgSrc] = useState("/img/upload.png");

  const navigate = useNavigate()

  const handleImageUpload = (e) => {
    const img = e.target.files;

    const url = URL.createObjectURL(img[0]);
    setImgSrc(url);

    setFile(img);
  };

  const mutation = useMutation({mutationFn: createProduct, 
    onSuccess: (data) => {
        console.log(data, "create data");

        if(data.success) {
            toast.success("Olmos qo'shildi")
            setTimeout(() => {navigate("/admin/stones")}, 2000)
        }
    }
    })

  const handleFormSubmit = () => {
    if (
      file &&
      stone &&
      diamond_wt &&
      measurement &&
      color &&
      clarity &&
      cut &&
      cut_and_shape &&
      result &&
      weight &&
      fineness &&
      data
    ) {

        const formData = new FormData()


        formData.append("file", file[0])
        formData.append("stone", stone)
        formData.append("diamond_wt", diamond_wt)
        formData.append("measurement", measurement)
        formData.append("color", color)
        formData.append("clarity", clarity)
        formData.append("cut", cut)
        formData.append("cut_and_shape", cut_and_shape)
        formData.append("result", result)
        formData.append("weight", weight)
        formData.append("fineness", fineness)
        formData.append("data", data)
        mutation.mutate(formData)

    } else {
      toast.error("Barcha joylarni to'ldiring");
    }
  };

  return (
    <div className="dashboard-create">
      <div className="dashboard-create_container">
        <div className="dashboard-create_content">
          <h2 className="dashboard_heading">Olmos qo'shish</h2>
          <TextField
            label="Stone"
            value={stone}
            onChange={(e) => setStone(e.target.value)}
          />
          {/* <TextField label="report_no" value={report_no} onChange={(e) => setReport_no(e.target.value)} /> */}
          <TextField
            label="diamond_wt"
            value={diamond_wt}
            onChange={(e) => setDiamond_wt(e.target.value)}
          />
          <TextField
            label="measurement"
            value={measurement}
            onChange={(e) => setMeasurement(e.target.value)}
          />
          <TextField
            label="color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
          <TextField
            label="clarity"
            value={clarity}
            onChange={(e) => setClarity(e.target.value)}
          />
          <TextField
            label="cut"
            value={cut}
            onChange={(e) => setCut(e.target.value)}
          />
          <TextField
            label="cut_and_shape"
            value={cut_and_shape}
            onChange={(e) => setCut_and_shape(e.target.value)}
          />
          <TextField
            label="result"
            value={result}
            onChange={(e) => setResult(e.target.value)}
          />
          <TextField
            label="weight"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
          <TextField
            label="fineness"
            value={fineness}
            onChange={(e) => setFineness(e.target.value)}
          />
          <TextField
            label="data"
            value={data}
            onChange={(e) => setData(e.target.value)}
          />

          <div className="media-input_wrapper">
            <input
              id="image-input"
              type="file"
              accept="image/*"
              className="media-input"
              onChange={handleImageUpload}
            />
            <img src={imgSrc} alt="" className="media-input_img" />
            <label htmlFor="image-input" className="media-input_btn">
              Add image
            </label>
          </div>

          <button
            className="dashboard-create_submit-btn"
            onClick={handleFormSubmit}
          >
            Tasdiqlash
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminCreateStoneInner;
