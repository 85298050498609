import { TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import { createNews, createProduct } from "../../data/data.fn";
import { useNavigate } from "react-router-dom";

const AdminCreateNewsInner = () => {
  const [title_uz, setTitle_uz] = useState("");
  const [title_ru, setTitle_ru] = useState("");
  const [title_en, setTitle_en] = useState("");
  const [text_uz, setText_uz] = useState("");
  const [text_ru, setText_ru] = useState("");
  const [text_en, setText_en] = useState("");
  const [date, setDate] = useState()


  const [file, setFile] = useState(null);
  const [imgSrc, setImgSrc] = useState("/img/upload.png");

  const navigate = useNavigate()

  const handleImageUpload = (e) => {
    const img = e.target.files;

    const url = URL.createObjectURL(img[0]);
    setImgSrc(url);

    setFile(img);
  };

  const mutation = useMutation({mutationFn: createNews, 
    onSuccess: (data) => {
        console.log(data, "create data");

        if(data.success) {
            toast.success("Yangilik qo'shildi")
            setTimeout(() => {navigate("/admin/news")}, 2000)
        }
    }
    })

  const handleFormSubmit = () => {
    if (
        file && title_uz && title_ru && title_en && text_uz && text_ru && text_en && date
    ) {

        const formData = new FormData()


        formData.append("file", file[0])
        formData.append("title_uz", title_uz)
        formData.append("title_ru", title_ru)
        formData.append("title_en", title_en)
        formData.append("text_uz", text_uz)
        formData.append("text_ru", text_ru)
        formData.append("text_en", text_en)
        formData.append("date", date)
        

        mutation.mutate(formData)

    } else {
      toast.error("Barcha joylarni to'ldiring");
    }
  };

  return (
    <div className="dashboard-create">
      <div className="dashboard-create_container">
        <div className="dashboard-create_content">
          <h2 className="dashboard_heading">Yangilik qo'shish</h2>
          <TextField
            label="title_uz"
            value={title_uz}
            onChange={(e) => setTitle_uz(e.target.value)}
          />
          {/* <TextField label="report_no" value={report_no} onChange={(e) => setReport_no(e.target.value)} /> */}
          <TextField
            label="title_ru"
            value={title_ru}
            onChange={(e) => setTitle_ru(e.target.value)}
          />
          <TextField
            label="title_en"
            value={title_en}
            onChange={(e) => setTitle_en(e.target.value)}
          />
          <TextField
            label="text_uz"
            value={text_uz}
            onChange={(e) => setText_uz(e.target.value)}
          />
          <TextField
            label="text_ru"
            value={text_ru}
            onChange={(e) => setText_ru(e.target.value)}
          />
          <TextField
            label="text_en"
            value={text_en}
            onChange={(e) => setText_en(e.target.value)}
          />
          <TextField
            label="Date"
            value={date}
            type="date"
            onChange={(e) => setDate(e.target.value)}
          />
         

          <div className="media-input_wrapper">
            <input
              id="image-input"
              type="file"
              accept="image/*"
              className="media-input"
              onChange={handleImageUpload}
            />
            <img src={imgSrc} alt="" className="media-input_img" />
            <label htmlFor="image-input" className="media-input_btn">
              Add image
            </label>
          </div>

          <button
            className="dashboard-create_submit-btn"
            onClick={handleFormSubmit}
          >
            Tasdiqlash
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminCreateNewsInner;
