import AdminCreateNewsInner from "../../component/admin-create-news"
import AuthProvider from "../../context/auth"
import AdminLayout from "../../layout"

const AdminCreateNews = () => {
    return (
        <AuthProvider>
            <AdminLayout>
                <AdminCreateNewsInner />
            </AdminLayout>
        </AuthProvider>
    )
}


export default AdminCreateNews