import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { editProduct } from "../../data/data.fn";
import { useEffect } from "react";
import { baseUrl } from "../../utils/axios";
import { toast } from "react-toastify";

const EditStoneModal = ({
  isEdit,
  setEdit,
  currentStone,
  setCurrentStone,
  refetch,
}) => {
  const [stone, setStone] = useState("");
    const [report_no, setReport_no] = useState("")
  const [diamond_wt, setDiamond_wt] = useState("");
  const [measurement, setMeasurement] = useState("");
  const [color, setColor] = useState("");
  const [clarity, setClarity] = useState("");
  const [cut, setCut] = useState("");
  const [cut_and_shape, setCut_and_shape] = useState("");
  const [result, setResult] = useState("");
  const [weight, setWeight] = useState("");
  const [fineness, setFineness] = useState("");
  const [data, setData] = useState("");

  const [file, setFile] = useState(null);
  const [imgSrc, setImgSrc] = useState("/img/upload.png");

  const handleImageUpload = (e) => {
    const img = e.target.files;

    const url = URL.createObjectURL(img[0]);
    setImgSrc(url);

    setFile(img);
  };

  const handleClose = () => {
    setEdit(false);
    setCurrentStone(null);
  };

  const mutation = useMutation({mutationFn: editProduct,
    onSuccess: (data) => {
        console.log(data, "edit data");

        if(data.success) {
            toast.success("Olmos o'zgartirildi")
            refetch()
        }
    }
})


useEffect(() => {
    if(currentStone) {
        setStone(currentStone?.stone)
        setDiamond_wt(currentStone?.diamond_wt)
        setMeasurement(currentStone?.measurement)
        setColor(currentStone?.color)
        setClarity(currentStone?.clarity)
        setCut(currentStone?.cut)
        setCut_and_shape(currentStone?.cut_and_shape)
        setResult(currentStone?.result)
        setWeight(currentStone?.weight)
        setFineness(currentStone?.fineness)
        setData(currentStone?.data)
        setReport_no(currentStone?.report_no)
    }
}, [currentStone])



  const handleEditStone = () => {
    const formData = new FormData()


    formData.append("file", file ? file[0] : currentStone?.image)
    formData.append("stone", stone)
    formData.append("diamond_wt", diamond_wt)
    formData.append("measurement", measurement)
    formData.append("color", color)
    formData.append("clarity", clarity)
    formData.append("cut", cut)
    formData.append("cut_and_shape", cut_and_shape)
    formData.append("result", result)
    formData.append("weight", weight)
    formData.append("fineness", fineness)
    formData.append("data", data)
    formData.append("report_no",report_no)

    mutation.mutate(formData)

    mutation.mutate({id: currentStone?._id, formData})

    setEdit(false)
    setCurrentStone(null)
  }

  return (
    <div className="">
      <Dialog
        open={isEdit}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`${stone?.stone} ni o'zgartirish`}
        </DialogTitle>
        <DialogContent>
          <div className="dashboard-create_content py-3">
            <TextField
              label="Stone"
              value={stone}
              onChange={(e) => setStone(e.target.value)}
            />
            <TextField label="report_no" value={report_no} onChange={(e) => setReport_no(e.target.value)} />
            <TextField
              label="diamond_wt"
              value={diamond_wt}
              onChange={(e) => setDiamond_wt(e.target.value)}
            />
            <TextField
              label="measurement"
              value={measurement}
              onChange={(e) => setMeasurement(e.target.value)}
            />
            <TextField
              label="color"
              value={color}
              onChange={(e) => setColor(e.target.value)}
            />
            <TextField
              label="clarity"
              value={clarity}
              onChange={(e) => setClarity(e.target.value)}
            />
            <TextField
              label="cut"
              value={cut}
              onChange={(e) => setCut(e.target.value)}
            />
            <TextField
              label="cut_and_shape"
              value={cut_and_shape}
              onChange={(e) => setCut_and_shape(e.target.value)}
            />
            <TextField
              label="result"
              value={result}
              onChange={(e) => setResult(e.target.value)}
            />
            <TextField
              label="weight"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
            />
            <TextField
              label="fineness"
              value={fineness}
              onChange={(e) => setFineness(e.target.value)}
            />
            <TextField
              label="data"
              value={data}
              onChange={(e) => setData(e.target.value)}
            />

            <div className="media-input_wrapper">
              <input
                id="image-input"
                type="file"
                accept="image/*"
                className="media-input"
                onChange={handleImageUpload}
              />
              <img src={file ? imgSrc : (baseUrl + "uploads/" + currentStone?.image)} alt="" className="media-input_img" />
              <label htmlFor="image-input" className="media-input_btn">
                Change image
              </label>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Bekor qilish</Button>
          <Button onClick={handleEditStone}>Tasdiqlash</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditStoneModal;
