import React from "react";
import "../../assets/libs/@iconscout/unicons/css/line.css";
import "../../assets/libs/@mdi/font/css/materialdesignicons.min.css";
import "../../assets/css/tailwind.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MobileNavbar from "./mobileNavbar";
import { useTranslation } from "react-i18next";

export default function Navbar(props) {
  const { navClass, navJustify } = props;

  window.addEventListener("scroll", windowScroll);

  const { t, i18n } = useTranslation();

  const [isMenu, setisMenu] = useState(false);

  const toggleMenu = () => {
    setisMenu(!isMenu);
    if (document.getElementById("navigation")) {
      const anchorArray = Array.from(
        document.getElementById("navigation").getElementsByTagName("a")
      );
      anchorArray.forEach((element) => {
        element.addEventListener("click", (elem) => {
          const target = elem.target.getAttribute("href");
          if (target !== "") {
            if (elem.target.nextElementSibling) {
              var submenu = elem.target.nextElementSibling.nextElementSibling;
              submenu.classList.toggle("open");
            }
          }
        });
      });
    }
  };

  // useEffect(() => {
  //   activateMenu();
  //   window.scrollTo(0, 0);
  // }, []);

  function windowScroll() {
    const navbar = document.getElementById("topnav");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      if (navbar !== null) {
        navbar?.classList.add("nav-sticky");
      }
    } else {
      if (navbar !== null) {
        navbar?.classList.remove("nav-sticky");
      }
    }

    const mybutton = document.getElementById("back-to-top");
    if (mybutton != null) {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        mybutton.classList.add("flex");
        mybutton.classList.remove("hidden");
      } else {
        mybutton.classList.add("hidden");
        mybutton.classList.remove("flex");
      }
    }
  }

  // const activateMenu = () => {
  //   var menuItems = document.getElementsByClassName("sub-menu-item");
  //   if (menuItems) {
  //     var matchingMenuItem = null;
  //     for (var idx = 0; idx < menuItems.length; idx++) {
  //       if (menuItems[idx].href === window.location.href) {
  //         matchingMenuItem = menuItems[idx];
  //       }
  //     }
  //     if (matchingMenuItem) {
  //       matchingMenuItem.classList.add("active");

  //       var immediateParent = getClosest(matchingMenuItem, "li");

  //       if (immediateParent) {
  //         immediateParent.classList.add("active");
  //       }

  //       var parent = getClosest(immediateParent, ".child-menu-item");
  //       if (parent) {
  //         parent.classList.add("active");
  //       }

  //       var parent = getClosest(parent || immediateParent, ".parent-menu-item");

  //       if (parent) {
  //         parent.classList.add("active");

  //         var parentMenuitem = parent.querySelector(".menu-item");
  //         if (parentMenuitem) {
  //           parentMenuitem.classList.add("active");
  //         }

  //         var parentOfParent = getClosest(parent, ".parent-parent-menu-item");
  //         if (parentOfParent) {
  //           parentOfParent.classList.add("active");
  //         }
  //       } else {
  //         var parentOfParent = getClosest(
  //           matchingMenuItem,
  //           ".parent-parent-menu-item"
  //         );
  //         if (parentOfParent) {
  //           parentOfParent.classList.add("active");
  //         }
  //       }
  //     }
  //   }
  // };

  // const getClosest = (elem, selector) => {
  //   // Element.matches() polyfill
  //   if (!Element.prototype.matches) {
  //     Element.prototype.matches =
  //       Element.prototype.matchesSelector ||
  //       Element.prototype.mozMatchesSelector ||
  //       Element.prototype.msMatchesSelector ||
  //       Element.prototype.oMatchesSelector ||
  //       Element.prototype.webkitMatchesSelector ||
  //       function (s) {
  //         var matches = (this.document || this.ownerDocument).querySelectorAll(
  //             s
  //           ),
  //           i = matches.length;
  //         while (--i >= 0 && matches.item(i) !== this) {}
  //         return i > -1;
  //       };
  //   }

  //   // Get the closest matching element
  //   for (; elem && elem !== document; elem = elem.parentNode) {
  //     if (elem.matches(selector)) return elem;
  //   }
  //   return null;
  // };

  const [openMenu, setOpenMenu] = useState(false);

  const languages = [
    {
      id: 1,
      lang: "uz",
      image: "/img/uz.png",
    },
    {
      id: 2,
      lang: "ru",
      image: "/img/ru.png",
    },
    {
      id: 3,
      lang: "en",
      image: "/img/en.png",
    },
  ];

  const [lang, setLang] = useState(
    languages?.find((el) => i18n.language?.includes(el.lang))
  );

  const [langOpen, setLangOpen] = useState(false);

  var navbarHtml = (
    <>
      <nav
        id="topnav"
        className={`defaultscroll ${
          navClass === "nav-light"
            ? ""
            : navClass === "nav-sticky"
            ? "bg-white dark:bg-slate-900"
            : ""
        }`}
      >
        <div className="container  header_container ">
          <Link className={`header_logo`} to="/">
            <span className=" flex items-center gap-5">
              <img
                src={"/logo.png"}
                className=""
                width={50}
                height={50}
                alt=""
              />
              <span className="text-sm md:text-xl">
                Diamonds and colored stones bourse
              </span>
            </span>
            {/* <div className=" hidden dark:flex items-center gap-2">
              <img
                src={"/logo.png"}
                height={44}
                width={44}
                className="hidden dark:inline-block"
                alt=""
              />
              <span className="text-sm md:text-xl" >Diamonds and colored stones bourse</span>
            </div> */}
          </Link>

          <ul style={{ color: props.text ?? "inherit" }} className="header_nav">
            <li className="header_nav-item">
              <a href={"/#about"} className="header_nav-item-link">
                {t("about-us")}
              </a>
            </li>
            <li className="header_nav-item">
              <a href={"/#services"} className="header_nav-item-link">
                {t("services")}
              </a>
            </li>
            <li className="header_nav-item">
              <a href={"/#stones"} className="header_nav-item-link">
                {t("stones")}
              </a>
            </li>
            <li className="header_nav-item">
              <a href={"/#faq"} className="header_nav-item-link">
                faq
              </a>
            </li>
            {/* <li className="header_nav-item">
              <a href={"/#comments"} className="header_nav-item-link">{t("clients")}</a>
            </li> */}
            <li className="header_nav-item">
              <a href={"/#blog"} className="header_nav-item-link">
                {t("blog")}
              </a>
            </li>
          </ul>

          <div className="flex items-center gap-4 ">
          <div className="" style={{position: "relative"}} >
            <button
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
              className=""
              onClick={() => {setLangOpen(e => !e)}}
            >
              <span className="uppercase">{lang?.lang}</span>
              <img
                style={{ width: "30px", height: "24px", objectFit: "contain" }}
                src={lang?.image}
                alt=""
                className="lang-image"
              />
            </button>

            <div
              style={{ display: langOpen ? "flex" : "none", flexDirection: "column", position: "absolute" , top: "100%" , right: "-20px", padding: "10px" , borderRadius: "6px", backgroundColor:  "#fff", zIndex: "10", width: "max-content", gap: "10px" }}
              className=""
            >
              {languages?.map((el) => {
                if (el.id != lang.id) {
                  return (
                    <button
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                      className=""
                      onClick={() => {
                        setLang(el)
                        setLangOpen(false)
                        i18n.changeLanguage(el.lang)
                      }}
                    >
                      <span className="uppercase text-black">{el?.lang}</span>
                      <img
                        style={{
                          width: "30px",
                          height: "24px",
                          objectFit: "contain",
                        }}
                        src={el?.image}
                        alt=""
                        className="lang-image"
                      />
                    </button>
                  );
                }
              })}
            </div>
          </div>

          <button
            className="header_toggler"
            onClick={() => {
              setOpenMenu(true);
            }}
          >
            <span className="toggler-line "></span>
            <span className="toggler-line "></span>
            <span className="toggler-line "></span>
          </button>
          </div>
        </div>
        <MobileNavbar open={openMenu} setOpen={setOpenMenu} />
      </nav>
    </>
  );
  return <div>{navbarHtml}</div>;
}
