import { useQuery } from "@tanstack/react-query";
import { adminMe, getNews, getProducts, getOneProduct} from "./data.fn";


export const useProducts = () => useQuery({queryKey: ["products"], queryFn: getProducts})

export const useOneProduct = (id) => useQuery({queryKey: [`products/${id}`], enabled: !!id,  queryFn: () => getOneProduct(id)})


export const useNews = () => useQuery({queryKey: ["news"], queryFn: getNews})



export const useMe = () => useQuery({queryKey: ["me"], queryFn: adminMe})




