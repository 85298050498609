import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  section: {
    backgroundColor: "grey",
    padding: 50,
  }
});

const PdfDocument = () =>  (
    <Document>
      <Page  size="A4" orientation="landscape">
           <View style={styles.section} >
            <Text> fsdkjfsdnfkjsnd</Text>
           </View>
      </Page>
    </Document>
  );


export default PdfDocument