import { baseUrl } from "../../utils/axios"

const AdminStone = ({item, setStone, setEdit, setIsDelete, index}) => {
    return (
        <tr className="admin-stone-table-row">
            <td className="stone-table-col">
                {index + 1}
            </td>
            <td className="stone-table-col">
            <img src={baseUrl + "uploads/" +  item?.image} alt="" className="admin-stone_img" />
            </td>
            <td className="stone-table-col">
                {item?.report_no}
            </td>
            <td className="stone-table-col">
                {item?.stone}
            </td>
            <td className="stone-table-col">
                {item?.diamond_wt}
            </td>
            <td className="stone-table-col">
                {item?.measurement}
            </td>
            <td className="stone-table-col">
                {item?.color}
            </td>
            <td className="stone-table-col">
                {item?.clarity}
            </td>
            <td className="stone-table-col">
                {item?.cut}
            </td>
            <td className="stone-table-col">
                {item?.cut_and_shape}
            </td>
            <td className="stone-table-col">
                {item?.result}
            </td>
            <td className="stone-table-col">
                {item?.weight}
            </td>
            <td className="stone-table-col">
                {item?.fineness}
            </td>
            <td className="stone-table-col">
                {item?.data}
            </td>
            <td className="stone-table-col">
            <div style={{display: "flex", flexDirection: "column" , gap: "5px"}} >
            <button className="dashboard_link w-full " onClick={() => {
                    setEdit(true)
                    setStone(item)
                }} >Edit</button>
                <button className="dashboard_link-default text-white w-full bg-red-600"  onClick={() => {
                    setIsDelete(true)
                    setStone(item)
                }} >Delete</button>
            </div>
            </td>
            {/* <div className="admin-stone_content">
                <div className="admin-stone_text-wrapper"><span>stone :</span> <span>{item?.stone}</span> </div>
                <div className="admin-stone_text-wrapper"><span>report no :</span> <span>{item?.report_no}</span> </div>
                <div className="admin-stone_text-wrapper"><span>diamond_wt :</span> <span>{item?.diamond_wt}</span> </div>
                <div className="admin-stone_text-wrapper"><span>measurement :</span> <span>{item?.measurement}</span> </div>
                <div className="admin-stone_text-wrapper"><span>color :</span> <span>{item?.color}</span> </div>
                <div className="admin-stone_text-wrapper"><span>clarity :</span> <span>{item?.clarity}</span> </div>
                <div className="admin-stone_text-wrapper"><span>cut :</span> <span>{item?.cut}</span> </div>
                <div className="admin-stone_text-wrapper"><span>cut_and_shape :</span> <span>{item?.cut_and_shape}</span> </div>
                <div className="admin-stone_text-wrapper"><span>result :</span> <span>{item?.result}</span> </div>
                <div className="admin-stone_text-wrapper"><span>weight :</span> <span>{item?.weight}</span> </div>
                <div className="admin-stone_text-wrapper"><span>fineness :</span> <span>{item?.fineness}</span> </div>
                <div className="admin-stone_text-wrapper"><span>data :</span> <span>{item?.data}</span> </div>
                <button className="dashboard_link w-full " onClick={() => {
                    setEdit(true)
                    setStone(item)
                }} >Edit</button>
                <button className="dashboard_link-default text-white w-full bg-red-600"  onClick={() => {
                    setIsDelete(true)
                    setStone(item)
                }} >Delete</button>
            </div> */}
        </tr>
    )
}

export default AdminStone