import AdminStonesInner from "../../component/admin-stones";
import AuthProvider from "../../context/auth";
import AdminLayout from "../../layout";

const AdminStones = () => {
  return (
    <AuthProvider>
      <AdminLayout>
        <AdminStonesInner />
      </AdminLayout>
    </AuthProvider>
  );
};

export default AdminStones;
