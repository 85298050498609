import { baseUrl } from "../../utils/axios"

const AdminNewsItem = ({item, setCurrentNews, setEdit, setIsDelete}) => {
    return (
        <div className="admin-stone">
            <img src={baseUrl + "uploads/" +  item?.image} alt="" className="admin-stone_img--big" />
            <div className="admin-stone_content">
                <div className="admin-stone_text-wrapper"><span>title_uz :</span> <span>{item?.title_uz}</span> </div>
                <div className="admin-stone_text-wrapper"><span>title_ru :</span> <span>{item?.title_ru}</span> </div>
                <div className="admin-stone_text-wrapper"><span>title_en :</span> <span>{item?.title_en}</span> </div>
                <div className="admin-stone_text-wrapper"><span>text_uz :</span> <span>{item?.text_uz}</span> </div>
                <div className="admin-stone_text-wrapper"><span>text_ru :</span> <span>{item?.text_ru}</span> </div>
                <div className="admin-stone_text-wrapper"><span>text_en :</span> <span>{item?.text_en}</span> </div>
                <div className="admin-stone_text-wrapper"><span>date :</span> <span>{item?.date}</span> </div>
                <button className="dashboard_link w-full " onClick={() => {
                    setEdit(true)
                    setCurrentNews(item)
                }} >Edit</button>
                <button className="dashboard_link-default text-white w-full bg-red-600"  onClick={() => {
                    setIsDelete(true)
                    setCurrentNews(item)
                }} >Delete</button>
            </div>
        </div>
    )
}

export default AdminNewsItem