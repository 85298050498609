import { createContext } from "react";
import { useMe } from "../data/data.service";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext()


const AuthProvider = ({children}) => {

    const {data, isLoading } = useMe()

    const navigate = useNavigate()

    useEffect(() => {
        if(localStorage.getItem("token")) {
            if(isLoading && !data?.success) {

            }else if(isLoading && data?.success) {

            }else if(!isLoading && data?.success) {

            }else {
                navigate("/login")
            }
        }else {
            navigate("/login")
        }
    }, [ data, isLoading])

    console.log(data);




 return (
    <AuthContext.Provider value={null}>
        {children}
    </AuthContext.Provider>
 )
}

export default AuthProvider