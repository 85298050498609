import AdminNewsInner from "../../component/admin-news"
import AuthProvider from "../../context/auth"
import AdminLayout from "../../layout"

const AdminNews = () => {
    return (
        <AuthProvider>
            <AdminLayout>
                <AdminNewsInner />
            </AdminLayout>
        </AuthProvider>
    )
}

export default AdminNews