import { createRef, lazy, useEffect, useState } from "react";

// const ReactToPdf = lazy(() => import("react-to-pdf"))

import ReactToPdf , { Resolution, Margin, Options }from "react-to-pdf";
import Footer from "../../component/Footer/footer";
import Navbar from "../../component/Navbar/navbar";
import { useParams } from "react-router-dom";
import { useOnedata } from "../../data/data.service";
import { useMemo } from "react";
import { baseUrl } from "../../utils/axios";
import QR from './qr.png'
const FirstPdfPage = ({data}) => {
  const ref = createRef();
  const options = {
    orientation: "landscape",
    unit: "in",
    format: [4.5, 7],
  };

  const [scale, setScale] = useState(0.9);

  useEffect(() => {
    if (window.innerWidth < 720) {
      if (window.innerWidth < 720 && window.innerWidth > 600) {
        setScale(1);
      } else if (window.innerWidth < 600 && window.innerWidth > 500) {
        setScale(1.1);
      } else if (window.innerWidth < 500 && window.innerWidth > 400) {
        setScale(1.2);
      } else if (window.innerWidth < 400 && window.innerWidth > 300) {
        setScale(1.3);
      } else {
        setScale(1.3);
      }
    } else {
      setScale(0.9);
    }
  }, []);

  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      className=""
    >
        <div style={{ padding: "50px 0" }}>
          <ReactToPdf
            targetRef={ref}
            filename="file.pdf"
            options={options}
            x={0.1}
            y={0.1}
            scale={scale}
          >
            {({ toPdf }) => (
              <button className="pdf-btn" onClick={toPdf}>
                Скачать pdf
              </button>
            )}
          </ReactToPdf>
          <div className="pdf-card_wrapper">
            <div className="pdf-card" ref={ref}>
              <div className="pdf-card_top">
                <img src="/logo.png" alt="" className="pdf-card_logo" />
                <div className="pdf-card_top-center">
                  <p className="pdf-card_big-heading">Diamonds</p>
                  <p className="pdf-card_small-heading">
                    and colored stones bourse ltd.
                  </p>
                </div>
                <div className="pdf-card_top-right">
                  <p className="pdf-card_identification-heading">
                    Identification Card
                  </p>
                  <p className="pdf-card_identification">
                    Iso tr 11211 1995(e)
                  </p>
                  <p className="pdf-card_identification">Iso 9001 2015</p>
                </div>
              </div>
              <p className="pdf-card_center-text">
                Member of Gemological Institute of America Alumni Association
              </p>
              <div className="pdf-card_blue-text">
                <span>Report No: {data?.report_no}</span>
                <span>Data: {data?.data}</span>
              </div>
              <div className="pdf-card_bottom">
                <div className="pdf-card_bottom-left">
                  <div className="pdf-card_bottom-left-text-wrapper">
                    <p className="pdf-card_bottom-left-text">Diamond WT</p>
                    <span>:</span>
                    <p className="pdf-card_bottom-left-text">
                      {data?.diamond_wt}
                    </p>
                  </div>
                  <div className="pdf-card_bottom-left-text-wrapper">
                    <p className="pdf-card_bottom-left-text">Measurement</p>
                    <span>:</span>
                    <p className="pdf-card_bottom-left-text">
                      {data?.measurement}
                    </p>
                  </div>
                  <div className="pdf-card_bottom-left-text-wrapper">
                    <p className="pdf-card_bottom-left-text">Color</p>
                    <span>:</span>
                    <p className="pdf-card_bottom-left-text">
                      {data?.color}
                    </p>
                  </div>
                  <div className="pdf-card_bottom-left-text-wrapper">
                    <p className="pdf-card_bottom-left-text">Clarity</p>
                    <span>:</span>
                    <p className="pdf-card_bottom-left-text">
                      {data?.clarity}
                    </p>
                  </div>
                  <div className="pdf-card_bottom-left-text-wrapper">
                    <p className="pdf-card_bottom-left-text">Cut</p>
                    <span>:</span>
                    <p className="pdf-card_bottom-left-text">{data?.cut}</p>
                  </div>
                  <div className="pdf-card_bottom-left-text-wrapper">
                    <p className="pdf-card_bottom-left-text">Shape & Cut</p>
                    <span>:</span>
                    <p className="pdf-card_bottom-left-text">
                      {data?.cut_and_shape}
                    </p>
                  </div>
                  <div className="pdf-card_bottom-left-text-wrapper">
                    <p className="pdf-card_bottom-left-text">Result</p>
                    <span>:</span>
                    <p className="pdf-card_bottom-left-text">
                      {data?.result}
                    </p>
                  </div>
                  <div className="pdf-card_bottom-left-text-wrapper">
                    <p className="pdf-card_bottom-left-text">Stone</p>
                    <span>:</span>
                    <p className="pdf-card_bottom-left-text">
                      {data?.stone}
                    </p>
                  </div>
                  <div className="pdf-card_bottom-left-text-wrapper">
                    <p className="pdf-card_bottom-left-text">Weight of Item(s)</p>
                    <span>:</span>
                    <p className="pdf-card_bottom-left-text">
                      {data?.weight}
                    </p>
                  </div>
                  <div className="pdf-card_bottom-left-text-wrapper">
                    <p className="pdf-card_bottom-left-text">Finens of Metal(s)</p>
                    <span>:</span>
                    <p className="pdf-card_bottom-left-text">
                      {data?.fineness}
                    </p>
                  </div>

                  {/* <p className="pdf-card_weight-text">
                    Weight of Item(s): {data?.weight}
                  </p>
                  <p className="pdf-card_weight-text">
                    Finens of Metal(s): {data?.fineness}
                  </p> */}
                </div>
                <div className="pdf-card_bottom-right">
                  <img
                    src={baseUrl + "uploads/" + data?.image}
                    alt="image"
                    // className="pdf-card_bottom-right-img" 
                  />
                   <img
                    src={QR}
                    alt="image"
                    className="pdf-card_bottom-right-img-last"
                  />
                  {/* <div className="">
                    <p className="pdf-card_authograf"></p>
                    <p className="pdf-card_author">Gemologist G.I.A</p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default FirstPdfPage;
