import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { baseUrl } from "../../utils/axios";
import { useMemo, useState } from "react";
// Create styles


const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = () => {
      const base64data = reader.result;   
      resolve(base64data);
    }
  });
}


const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    color: "#060418",
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
    margin: "0 auto",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "5px solid blue",
  },
  headerLeft: {
    display: "flex",
    gap: 20,
    padding: 10,
    flexDirection: "row",
    width: "50%",
    borderRight: "1px solid blue",
  },
  headerLogo: {
    width: 70,
    height: 70,
    objectFit: "contain",
  },
  headerRight: {
    display: "flex",
    gap: 20,
    padding: 15,
    flexDirection: "row",
    width: "50%",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  headerLogoBigText: {
    fontSize: 43,
    textTransform: "uppercase",
  },
  headerLogoSmallText: {
    fontSize: 13,
    textTransform: "uppercase",
  },
  headerRightFirstText: {
    fontSize: 14,
    textTransform: "uppercase",
  },
  headerRightSecondText: {
    fontSize: 22,
    textTransform: "capitalize",
  },
  main: {
    display: "flex",
    flexDirection: "row",
    padding: "0 50px",
    fontSize: 13,
  },
  mainLeft: {
    width: "50%",
    borderRight: "1px solid blue",
    padding: "10px 15px",
  },
  mainRight: {
    width: "50%",
    padding: "20px 15px ",
  },
  textFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  centerFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  boldText: {
    fontWeight: "semibold",
  },
  boldTextMb: {
    fontWeight: "semibold",
    marginBottom: 10,
  },
  margin: (value = "0") => {
    return {
      margin: value,
    };
  },
  fontSize: (value = "inherit") => {
    return {
      fontSize: value,
    };
  },
});

// Create Document Component
function BasicDocument({ data }) {
  console.log("pdfadfcsd");


  const [pdfImage, setPdfImage] = useState("https://wogme.web-sayt.uz/uploads/de7dc6f2-fef9-4f14-9869-4667c1d9c8fe.png")

  

  // useMemo(() => {
  //   if(data) {
  //     getBase64FromUrl(data?.image).then(res => {
  //       setPdfImage(res)
  //       console.log(res);
  //     })
  //   }
  // }, [data])



  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="A4" orientation="landscape" style={styles.page}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <View>
                <Image style={styles.headerLogo} src={"/logo.png"} />
              </View>
              <View>
                <Text style={styles.headerLogoBigText}>Diamonds</Text>
                <Text style={styles.headerLogoSmallText}>
                  and colored stones bourse ltd.
                </Text>
              </View>
            </View>
            <View style={styles.headerRight}>
              <View>
                <Text style={styles.headerRightFirstText}>
                  ISO TR 11211 1995(E)
                </Text>
                <Text style={styles.headerRightFirstText}>ISO 9001-2015</Text>
              </View>
              <View>
                <Text style={styles.headerRightSecondText}>Diamond report</Text>
                <Text style={styles.headerRightSecondText}>
                  Brilliant hisoboti
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.main}>
            <View style={styles.mainLeft}>
              <View style={styles.centerFlex}>
                <Text
                  style={{
                    fontWeight: 600,
                    textDecoration: "underline",
                    fontSize: 16,
                  }}
                >
                  Report No: {data?.report_no}
                </Text>
                <Text
                  style={{
                    fontWeight: 600,
                    textDecoration: "underline",
                    fontSize: 16,
                  }}
                >
                  Data: {data?.data}
                </Text>
              </View>
              <Text style={{ marginBottom: 5 }}>
                Natural diamonds (Tabiiy brilliant)
              </Text>
              <Text style={{ fontSize: 16, marginBottom: 5 }}>
                Grading Information
              </Text>
              <View style={styles.textFlex}>
                <View>
                  <Text>Shape</Text>
                  <Text style={{ fontSize: 10, fontStyle: "italic" }}>
                    (Shakl)
                  </Text>
                </View>
                <Text>{data?.cut_and_shape}</Text>
              </View>
              <View style={styles.textFlex}>
                <View>
                  <Text>Measurement</Text>
                  <Text style={{ fontSize: 10, fontStyle: "italic" }}>
                    (karat og'irligi)
                  </Text>
                </View>
                <Text>{data?.measurement}</Text>
              </View>
              <View style={styles.textFlex}>
                <View>
                  <Text>Carat Weight</Text>
                  <Text style={{ fontSize: 10, fontStyle: "italic" }}>
                    (karat og'irligi)
                  </Text>
                </View>
                <Text>{data?.weight}</Text>
              </View>
              <View style={styles.textFlex}>
                <View>
                  <Text>Clarity Grade</Text>
                  <Text style={{ fontSize: 10, fontStyle: "italic" }}>
                    (Ravshanlik darajasi)
                  </Text>
                </View>
                <Text>{data?.clarity}</Text>
              </View>
              <View style={styles.textFlex}>
                <View>
                  <Text>Color Grade</Text>
                  <Text style={{ fontSize: 10, fontStyle: "italic" }}>
                    (rang darajasi)
                  </Text>
                </View>
                <Text>{data?.color}</Text>
              </View>
              <View style={styles.textFlex}>
                <View>
                  <Text>Cut Grade</Text>
                  <Text style={{ fontSize: 10, fontStyle: "italic" }}>
                    (kesim darajasi)
                  </Text>
                </View>
                <Text>{data?.cut}</Text>
              </View>
              <View style={styles.textFlex}>
                <View>
                  <Text>Proportions</Text>
                  <Text style={{ fontSize: 10, fontStyle: "italic" }}>
                    (Nisbatlar)
                  </Text>
                </View>
                <Text>{"______"}</Text>
              </View>
              <View style={styles.textFlex}>
                <View>
                  <Text>Polish</Text>
                  <Text style={{ fontSize: 10, fontStyle: "italic" }}>
                    (sayqallash)
                  </Text>
                </View>
                <Text>{"______"}</Text>
              </View>
              <View style={styles.textFlex}>
                <View>
                  <Text>Symmetry</Text>
                  <Text style={{ fontSize: 10, fontStyle: "italic" }}>
                    (simmetriya)
                  </Text>
                </View>
                <Text>{"______"}</Text>
              </View>
              <View style={styles.textFlex}>
                <View>
                  <Text>Flourescene</Text>
                  <Text style={{ fontSize: 10, fontStyle: "italic" }}>
                    (Ultrabinafsha)
                  </Text>
                </View>
                <Text>{"______"}</Text>
              </View>
              <View style={styles.textFlex}>
                <View>
                  <Text>Comments</Text>
                  <Text style={{ fontSize: 10, fontStyle: "italic" }}>
                    (Izoh)
                  </Text>
                </View>
                <Text>{data?.result}</Text>
              </View>
            </View>
            <View style={styles.mainRight}>
              <View style={{padding: 10, marginBottom: 15}} >
                <View style={styles.textFlex}>
                  <View>
                    <Text>Jewellery name</Text>
                    <Text style={{ fontSize: 10, fontStyle: "italic" }}>
                      (Buyum nomi)
                    </Text>
                  </View>
                <Text>{data?.result}</Text>
                </View>
                <View style={styles.textFlex}>
                  <View>
                    <Text>Jewellery weight</Text>
                    <Text style={{ fontSize: 10, fontStyle: "italic" }}>
                      (Buyum og'irligi)
                    </Text>
                  </View>
                  <Text>{data?.weight}</Text>
                </View>
                <View style={styles.textFlex}>
                  <View>
                    <Text>Jewellery metall(s)</Text>
                    <Text style={{ fontSize: 10, fontStyle: "italic" }}>
                      (Buyumning metall turi)
                    </Text>
                  </View>
                  <Text>{data?.fineness}</Text>
                </View>
              </View>
              <View style={{width: 200, height: 200, border: "1px solid blue", marginHorizontal: "auto", padding: 10}} >
                  <Image src={`${baseUrl}uploads/${data?.image}`}  style={{width: "100%", height: "100%", objectFit: "contain" }} />
                  {/* <Image src={"https://www.justone.uz/_next/image?url=https%3A%2F%2Fone.web-sayt.uz%2Fuploads%2F1bf07393-0da8-4472-9cfa-2ee2064e3371.jpg&w=640&q=75"}  style={{width: "100%", height: "100%", objectFit: "contain" }} /> */}
                  {/* <Image src={{ uri: `https://www.justone.uz/_next/image?url=https%3A%2F%2Fone.web-sayt.uz%2Fuploads%2F1bf07393-0da8-4472-9cfa-2ee2064e3371.jpg&w=640&q=75`, method: "GET", headers: {"Cache-Control": "no-cache"}, body: "",}} style={{width: "100%", height: "100%", objectFit: "contain" }} /> */}
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
export default BasicDocument;
