import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { editNews, editProduct } from "../../data/data.fn";
import { useEffect } from "react";
import { baseUrl } from "../../utils/axios";
import { toast } from "react-toastify";

const EditNewsModal = ({
  isEdit,
  setEdit,
  currentNews,
  setCurrentNews,
  refetch,
}) => {
    const [title_uz, setTitle_uz] = useState("");
    const [title_ru, setTitle_ru] = useState("");
    const [title_en, setTitle_en] = useState("");
    const [text_uz, setText_uz] = useState("");
    const [text_ru, setText_ru] = useState("");
    const [text_en, setText_en] = useState("");
    const [report_no, setReport_no] = useState('')
    const [date, setDate] = useState()

  const [file, setFile] = useState(null);
  const [imgSrc, setImgSrc] = useState("/img/upload.png");

  const handleImageUpload = (e) => {
    const img = e.target.files;

    const url = URL.createObjectURL(img[0]);
    setImgSrc(url);

    setFile(img);
  };

  const handleClose = () => {
    setEdit(false);
    setCurrentNews(null);
  };

  const mutation = useMutation({mutationFn: editNews,
    onSuccess: (data) => {
        console.log(data, "edit data");

        if(data.success) {
            toast.success("Olmos o'zgartirildi")
            refetch()
        }
    }
})


useEffect(() => {
    if(currentNews && isEdit) {
        setTitle_uz(currentNews?.title_uz)
        setTitle_ru(currentNews?.title_ru)
        setTitle_en(currentNews?.title_en)
        setText_uz(currentNews?.text_uz)
        setText_ru(currentNews?.text_ru)
        setText_en(currentNews?.text_en)
        setDate(currentNews?.date)
        
    }
}, [currentNews, isEdit])



  const handleEditNews = () => {
    const formData = new FormData()


    formData.append("file", file ? file[0] : currentNews?.image)
    formData.append("title_uz", title_uz)
    formData.append("report_no", report_no)
    formData.append("title_ru", title_ru)
    formData.append("title_en", title_en)
    formData.append("text_uz", text_uz)
    formData.append("text_ru", text_ru)
    formData.append("text_en", text_en)
    formData.append("date", date)

    mutation.mutate(formData)

    mutation.mutate({id: currentNews?._id, formData})

    setEdit(false)
    setCurrentNews(null)
  }

  return (
    <div className="">
      <Dialog
        open={isEdit}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`${currentNews?.title_uz?.slice(0, 50)}... ni o'zgartirish`}
        </DialogTitle>
        <DialogContent>
          <div className="dashboard-create_content py-3">
          <TextField
            label="title_uz"
            value={title_uz}
            onChange={(e) => setTitle_uz(e.target.value)}
          />
          <TextField label="report_no" value={report_no} onChange={(e) => setReport_no(e.target.value)} />
          <TextField
            label="title_ru"
            value={title_ru}
            onChange={(e) => setTitle_ru(e.target.value)}
          />
          <TextField
            label="title_en"
            value={title_en}
            onChange={(e) => setTitle_en(e.target.value)}
          />
          <TextField
            label="text_uz"
            value={text_uz}
            onChange={(e) => setText_uz(e.target.value)}
          />
          <TextField
            label="text_ru"
            value={text_ru}
            onChange={(e) => setText_ru(e.target.value)}
          />
          <TextField
            label="text_en"
            value={text_en}
            onChange={(e) => setText_en(e.target.value)}
          />
          <TextField
            label="Date"
            value={date}
            type="date"
            onChange={(e) => setDate(e.target.value)}
          />

            <div className="media-input_wrapper">
              <input
                id="image-input"
                type="file"
                accept="image/*"
                className="media-input"
                onChange={handleImageUpload}
              />
              <img src={file ? imgSrc : (baseUrl + "uploads/" + currentNews?.image)} alt="" className="media-input_img" />
              <label htmlFor="image-input" className="media-input_btn">
                Change image
              </label>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Bekor qilish</Button>
          <Button onClick={handleEditNews}>Tasdiqlash</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditNewsModal;
