import axios from "../utils/axios";

// getProducts
export const getProducts = async () =>
  axios
    .get("products")
    .then((res) => res.data)
    .catch((e) => e);


    export const getOneProduct = async (id) =>
  axios
    .get(`products/${id}`)
    .then((res) => res.data)
    .catch((e) => e);


// createProduct
export const createProduct = async (item) =>
  axios
    .post("products", item, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res.data)
    .catch((e) => e);

// deleteProduct
export const deleteProduct = async (id) =>
  axios
    .delete(`products/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res.data)
    .catch((e) => e);

// editProduct
export const editProduct = async ({ id, formData }) =>
  axios
    .put(`products/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res.data)
    .catch((e) => e);

// adminLogin
export const adminLogin = async (item) =>
  axios
    .post("users/login", item)
    .then((res) => res.data)
    .catch((e) => e);

// adminMe
export const adminMe = async () =>
  axios
    .get("users/me", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res.data)
    .catch((e) => e);

// getNews
export const getNews = async () =>
  axios
    .get("news")
    .then((res) => res.data)
    .catch((e) => e);

// createNews
export const createNews = async (item) =>
  axios
    .post("news", item, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res.data)
    .catch((e) => e);

// editNews
export const editNews = async ({ id, formData }) =>
  axios
    .put(`news/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res.data)
    .catch((e) => e);


    
// deleteNews
export const deleteNews = async (id) =>
axios
  .delete(`products/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
  .then((res) => res.data)
  .catch((e) => e);
