import { Link } from "react-router-dom";
import { useProducts } from "../../data/data.service";
import AdminStone from "../stone";
import { useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation } from "@tanstack/react-query";
import { deleteProduct } from "../../data/data.fn";
import { toast } from "react-toastify";
import EditStoneModal from "../edit-stone-modal";
import { stones_table_headers } from "../../utils/consts";
import { useMemo } from "react";

const AdminStonesInner = () => {
  const { data, isLoading, refetch } = useProducts();

  console.log(data);

  const [stone, setStone] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const [searchValue, setSearchVaue] = useState("")

  const handleClose = () => {
    setIsDelete(false)
    setStone(null)
  }

  const filteredData = useMemo(() => {
    if(data?.data?.Projects) {
      if(searchValue) {
        return data?.data?.Projects?.filter(item => item?.report_no?.includes(searchValue))
      }
      return data?.data?.Projects
    }
    return []
  }, [searchValue, data])


  const mutation = useMutation({mutationFn: deleteProduct,
    onSuccess: (data) => {

        if(data.success) {
            toast.success("Olmos o'chirildi")

            refetch()
            console.log(data);
        }

    },
    onError: () => {
        toast.error("Hatolik yuz berdi qaytadan urinib ko'ring")
        refetch()
    }
    
})

  const handleDeleteStone = () => {
    mutation.mutate(stone?._id)

    setIsDelete(false)
    setStone(null)
  }


  return (
    <div className="dashboard">
        <EditStoneModal isEdit={isEdit}  refetch={refetch} setEdit={setEdit} setCurrentStone={setStone} currentStone={stone} />
      <div>
        
        <Dialog
          open={isDelete}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Siz ${stone?.stone} ni o'chirib tashlashni hohlaysizmi?`}
          </DialogTitle>
          {/* <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Let Google help apps determine location. This means sending
              anonymous location data to Google, even when no apps are running.
            </DialogContentText>
          </DialogContent> */}
          <DialogActions>
            <Button onClick={handleClose}>Yo'q</Button>
            <Button onClick={handleDeleteStone}>
              Ha
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className="dashboard_container">
        <div className="dashboard_link-wrapper">
          <h2 className="dashboard_heading">Olmoslar</h2>
          <input value={searchValue} onChange={(e) => {setSearchVaue(e.target.value)}} type="text" className="dashboard_search" placeholder="Search by report no" />
          <Link to={"/admin/create/stone"} className="dashboard_link">
            Olmos qoshish
          </Link>
        </div>
        {isLoading ? (
            <b className="block text-center">Yuklanmoqda...</b>
          ) : (!isLoading && filteredData.length > 0 ? (
          //  <div className="dashboard_wrapper">
          //   {
          //   data?.data?.Projects?.map((item) => (
          //      <AdminStone
          //        key={item?._id}
          //        item={item}
          //        setStone={setStone}
          //        setEdit={setEdit}
          //        setIsDelete={setIsDelete}
          //      />
          //    ))
          //   }
          //  </div>
          <div className="stones_table-wrapper">
            <table className="stones_table">
              <thead>
                <tr className="stones_table-head-row">
                  {
                    stones_table_headers?.map(item =>  <td key={item.id} className="stone-table-col">{item.value}</td>)
                  }
                </tr>
              </thead>
              <tbody>
              {
                filteredData?.map((item, index) => (
                  <AdminStone
                  index={index}
                 key={item?._id}
                 item={item}
                 setStone={setStone}
                 setEdit={setEdit}
                 setIsDelete={setIsDelete}
               />
                ))
              }
              </tbody>
            </table>
          </div>
          ) : (
            <b className="block text-center">Olmoslar yo'q</b>
          ))}
       
      </div>
    </div>
  
  );
};

export default AdminStonesInner;
