import { useState } from "react";
import { Link } from "react-router-dom";

const AdminHeader = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <header className="admin-header">
      <div className={`admin-header_container ${isOpen ? "opened" : ""}`}>
        <Link to={"/admin"}>
          <img src="/logo.png" alt="" className="admin-header_logo" />
        </Link>
        <nav
          className={`admin-header_nav ${isOpen ? "open-admin-header" : ""}`}
        >
          <Link to={"/admin/stones"} className="admin-header_nav-link">
            Olmoslar
          </Link>
          <Link to={"/admin/create/stone"} className="admin-header_nav-link">
            Olmos qoshish
          </Link>
          <Link to={"/admin/news"} className="admin-header_nav-link">
            Yangiliklar
          </Link>
          <Link to={"/admin/create/news"} className="admin-header_nav-link">
            Yangiliklar qoshish
          </Link>
        </nav>
        <button
          className="nav-toggler"
          onClick={() => {
            setOpen((e) => !e);
          }}
        >
          <span className="toggler-line"></span>
          <span className="toggler-line"></span>
          <span className="toggler-line"></span>
        </button>
      </div>
    </header>
  );
};

export default AdminHeader;
