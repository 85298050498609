import axios from 'axios'
// http://localhost:5000/
// https://marketbobo-back.vercel.app/
// https://plankton-app-5yeaz.ondigitalocean.app/
// https://api.web-sayt.uz/

export const baseUrl="https://diamonds.web-sayt.uz/"
const instance = axios.create({
  baseURL: baseUrl + "api/"
});
if(typeof window !== 'undefined') {
    instance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
}
export default instance;