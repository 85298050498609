import AdminHeader from "./header"

const AdminLayout = ({children}) => {
    return (
        <div className="layout_wrapper">
            <AdminHeader />
            {children}
        </div>
    )
}

export default AdminLayout