import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const MobileNavbar = ({ open, setOpen }) => {

  const {t} = useTranslation()

  return (
    <div className={open ? "open-menu" : "close-menu"} onClick={() => {setOpen(false)}} >
      <div className="mobilenav" onClick={(e) => {e.stopPropagation()}} >
        <div className="toggler-wrapper">
        <button className="header_toggler" onClick={() => {setOpen(false)}} >
            <span className="toggler-line toggler-right "></span>
            <span className="toggler-line toggler-left"></span>
            {/* <span className="toggler-line "></span> */}
          </button>
        </div>
        <Link to={"/"} className="mobilenav_logo">
          <img src="/logo.png" alt="" className="mobilenav_logo-img" />
        </Link>
        <ul className="header_nav">
            <li className="header_nav-item">
              <a href={"/#about"} className="header_nav-item-link">{t("about-us")}</a>
            </li>
            <li className="header_nav-item">
              <a href={"/#services"} className="header_nav-item-link">{t("services")}</a>
            </li>
            <li className="header_nav-item">
              <a href={"/#stones"} className="header_nav-item-link">{t("stones")}</a>
            </li>
            <li className="header_nav-item">
              <a href={"/#faq"} className="header_nav-item-link">faq</a>
            </li>
            <li className="header_nav-item">
              <a href={"/#comments"} className="header_nav-item-link">{t("clients")}</a>
            </li>
            <li className="header_nav-item">
              <a href={"/#blog"} className="header_nav-item-link">{t("blog")}</a>
            </li>
          </ul>
      </div>
    </div>
  );
};

export default MobileNavbar;
