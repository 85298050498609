export const stones_table_headers = [
    { value: "No", id: 1 },
    { value: "Image", id: 19 },
    { value: "Report no", id: 2 },
    { value: "stone", id: 3 },
    { value: "diamond_wt", id: 4 },
    { value: "measurement", id: 5 },
    { value: "color", id: 6 },
    { value: "clarity", id: 7 },
    { value: "cut", id: 8},
    { value: "cut_and_shape", id: 9 },
    { value: "result", id: 10 },
    { value: "weight", id: 11 },
    { value: "fineness", id: 12 },
    { value: "data", id: 13 },
  ];